import GATSBY_COMPILED_MDX from "/opt/build/repo/content/works/brain-games/index.mdx";
import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import MuxPlayer from '@mux/mux-player-react';
import removeMarkdown from 'remove-markdown';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Meta from 'components/meta';
import RedirectToParent from 'components/redirect-to-parent';
import Wrapper from 'components/wrapper';
import {useElementSize} from 'utils/use-element-size';
import {useMediaConfig} from 'utils/use-media-config';
function MdxWorkVideo({location, pageContext, data: {mdx, mediaVideo}}) {
  if (typeof window === 'undefined') return null;
  const {frontmatter: fm} = mdx ?? ({});
  const {title, projectData} = pageContext ?? ({});
  if (!mediaVideo || !projectData) return React.createElement(RedirectToParent, {
    location: location
  });
  const videoData = Object.assign({}, mediaVideo, {
    pages: projectData.pages?.filter(item => {
      return item.route != location?.pathname.replace(/\/$/, '');
    })
  });
  const videoTitle = title ?? fm?.title ?? projectData.title;
  const mediaConfig = useMediaConfig();
  const [muxContainer, setMuxContainer] = useState(null);
  const muxContainerSize = useElementSize(muxContainer);
  const [muxPlayer, setMuxPlayer] = useState(null);
  useEffect(() => {
    if (muxPlayer) {
      const playerWidth = muxContainerSize.width;
      const playerHeight = Math.round(muxContainerSize.width * (mediaVideo.height / mediaVideo.width));
      mediaVideo.width = playerWidth;
      mediaVideo.height = playerHeight;
      muxPlayer.style.width = `${playerWidth}px`;
      muxPlayer.style.height = `${playerHeight}px`;
    }
  }, [muxContainerSize]);
  return React.createElement(Wrapper, {
    location: location,
    variant: "video",
    wrapperClass: "d-flex flex-column w-100 mx-auto",
    bodyClass: "bg-body-darker"
  }, React.createElement(Meta, {
    title: videoTitle,
    author: fm?.author,
    description: removeMarkdown(projectData.tagline)
  }), React.createElement(Row, {
    className: "text-center"
  }, React.createElement(Col, null, videoTitle && React.createElement("div", {
    className: "media-title fs-4 pt-3 pb-1"
  }, videoTitle, " ", projectData.year && React.createElement("span", null, ` (${projectData.year})`)), React.createElement("div", {
    ref: setMuxContainer,
    className: "border border-white border-opacity-25"
  }, React.createElement(MuxPlayer, {
    ref: setMuxPlayer,
    streamType: "on-demand",
    playbackId: mediaVideo?.playbackId,
    aspectRatio: mediaVideo?.aspect,
    height: mediaVideo?.height,
    placeholder: mediaVideo?.posterHash,
    poster: mediaVideo?.posterImage,
    width: mediaVideo?.width,
    metadata: {
      video_id: mediaVideo?.key,
      video_title: videoTitle
    }
  })), mediaVideo?.restrict && React.createElement("div", {
    className: "py-1 border border-top-0 border-white border-opacity-25"
  }, React.createElement("div", {
    className: "o-60 small text-uppercase"
  }, mediaConfig.restrict[mediaVideo.restrict])))), React.createElement(Row, {
    className: "py-3 g-1 justify-content-center text-nowrap"
  }, videoData?.pages?.map((item, index) => React.createElement(Col, {
    key: index,
    className: "flex-grow-0"
  }, React.createElement(Button, {
    className: "fs-6",
    variant: "primary",
    size: "sm",
    href: item.route
  }, item.label ?? `Link #${index}`))), React.createElement(Col, {
    className: "flex-grow-0"
  }, React.createElement(Button, {
    className: "fs-6",
    variant: "primary",
    size: "sm",
    href: "/catalog"
  }, "Project Catalog"))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MdxWorkVideo, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String, $key: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        author
        title
        year
      }
    }
    mediaVideo(key: { eq: $key }) {
      aspect
      height
      key
      playbackId
      posterHash
      posterImage
      publish
      restrict
      tileHash
      tileImage
      tileSmall
      title
      width
    }
  }
`;
